import React from "react"
import { graphql } from "gatsby"
import Layout from "../components2/Layout";
import Seo from "../components2/Seo";
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"


const PageWrapper = styled.div`
padding-top: 90px;
`

const AboutPageBanner = styled.section`
`

const AboutPageBannerInner = styled.div`
@media screen and (max-width: 1920px){
    max-width: 640px;
}

width: 94%;
margin-right: auto;
margin-left: auto;
`

const AboutPageBannerInnerContent = styled.div`

`

const AboutPageBannerInnerContentTitle = styled.h1`
@media screen and (max-width: 670px){
    font-size: 36px;
    font-size: 2.25rem;
}
    font-size: 52px;
    font-size: 3.25rem;
    font-family: "Anton","Arial",sans-serif;
    font-weight: 400;
    line-height: 120%;
    text-transform: uppercase;
    text-align:center;
`
const AboutPageBannerInnerContentText = styled.p`
line-height: 180%;
font-size: 14px;
font-size: .875rem;
margin-top: 30px
`
const AuthorsSection = styled.section`
padding-top: 80px;
padding-bottom: 80px;
`

const AuthorsSectionTitleWrapper = styled.div`
margin-bottom: 40px;
max-width: 870px;
width: 94%;
margin-right: auto;
margin-left: auto;
`

const AuthorsSectionTitle = styled.h2`
text-align:center;
@media screen and (max-width: 670px){
    font-size: 28px;
    font-size: 1.75rem;
}

@media screen and (max-width: 1024px){
    font-size: 42px;
    font-size: 2.625rem;
}
`
const AuthorsWrapper = styled.div`
`

const AuthorsUl = styled.ul`
@media screen and (min-width: 671px){
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
}

@media screen and (max-width: 1920px){
    max-width: 1330px;
}
width: 94%;
margin-right: auto;
margin-left: auto;
list-style: none;
`

const AuthorsLi = styled.li`
@media screen and (min-width: 671px){
    width: 30.8333333333%;
}

@media screen and (max-width: 1024px){
    font-size: 16px;
    font-size: 1rem;
    line-height: 180%;
}

@media screen and (min-width: 671px){
    &:not(:nth-child(3n+1)) {
        margin-left: 3.75%;
    }
}

text-align: center;
`

const AuthorImageWrapper = styled.div`
margin-left: auto;
margin-right: auto;
`

const AuthorTextWrapper = styled.div`
margin-top: 30px;
`

const AuthorName = styled.h5`
@media screen and (max-width: 1024px){
    font-size: 22px;
    font-size: 1.375rem;
}

font-family: "Anton","Arial",sans-serif;
font-weight: 400;
line-height: 120%;
text-transform: uppercase;
`

const AuthorSubtitle = styled.p`
@media screen and (min-width: 671px){
    margin-top: 20px;
}

@media screen and (max-width: 670px){
    margin-top: 20px;
}

display: inline-block;
border-bottom: 2px solid transparent;
font-size: .75rem;
font-family: "Epilogue","Arial",sans-serif;
font-weight: 700;
color: #FF692D;
text-transform: uppercase;
letter-spacing: .1em;
font-size: .75rem;
`

const AuthorBioWrapper = styled.div`
    margin-top: 40px;
`

const AuthorBioText = styled.p`
font-size: .75rem;
line-height:180%;
`



const AboutPage = () => {
  return (
    <Layout>
      <Seo title={`About Page For Charmed Crystal`} description={`This is the About page for Charmed Crystal`} />
    <PageWrapper>
        <AboutPageBanner>
            <AboutPageBannerInner>
                <AboutPageBannerInnerContent>
                    <AboutPageBannerInnerContentTitle>About Charmed Crystal</AboutPageBannerInnerContentTitle>
                    <AboutPageBannerInnerContentText>
                        Charmed Crystal began on the internet by Matthew Bryan in early 2022. 
                        The concept was pretty simple - to provide a resource for crystal collectors to learn about crystals and minerals.
                        We wanted to balance accurate scientific information with centuries of real world practice.
                        Today, our team of contributors range from crystal graders to gemstone-obsessed writers that 
                         all share a common - help you understand and use crystals. 
                    </AboutPageBannerInnerContentText>
                </AboutPageBannerInnerContent>
            </AboutPageBannerInner>
        </AboutPageBanner>
        <AuthorsSection>
            <AuthorsSectionTitleWrapper>
                <AuthorsSectionTitle>
                    MEET OUR TEAM
                </AuthorsSectionTitle>
            </AuthorsSectionTitleWrapper>
        <AuthorsWrapper>
            <AuthorsUl>
                <AuthorsLi>
                    <AuthorImageWrapper>
                    <StaticImage 
                    src={`../../static/img/matthew-celebrates-with-a-crystal.png`} 
                    alt="Matthew Bryan Celebrates Crystal Win"
                    imgStyle={{
                    borderRadius: "100%",
                    maxWidth: "287px"}} />                                
                    </AuthorImageWrapper>
                    <AuthorTextWrapper>
                    <AuthorName>Matthew Bryan</AuthorName>
                    <AuthorSubtitle><span>Founder of Charmed Crystal</span></AuthorSubtitle>
                    <AuthorBioWrapper>
                        <AuthorBioText>
                            Matthew founded Charmed Crystal in early 2022, writing the code and the articles for the site. 
                            Matthew is still actively involved with the site even with the addition of new writers. 
                            He currently manages (3) sites and (1) SEO agency. 
                            </AuthorBioText>
                    </AuthorBioWrapper>
                    </AuthorTextWrapper>
                </AuthorsLi>
            </AuthorsUl>
        </AuthorsWrapper>
        </AuthorsSection>
    </PageWrapper>
    </Layout>
  )
}


export const pageQuery = graphql`
  {
    allSitePage(
      filter: { path: { nin: ["/dev-404-page", "/404", "/404.html"] } }
    ) {
      nodes {
        path
      }
    }
  }
`

export default AboutPage